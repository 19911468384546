import { Card, CardContent } from "@/core/ui";

interface MatchesProps {
  data: {
    matches_image: {
      path: string;
      caption: string;
    };
  };
}

export const Matches: React.FC<MatchesProps> = ({ data }) => {
    return (
      <Card className='w-full shadow-board rounded-[40px] p-24'>
        <CardContent className='w-full h-full flex flex-col'>
            <img src={data.matches_image.path} alt={data.matches_image.caption} />
        </CardContent>
      </Card>
    );
  };