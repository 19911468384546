import { OliveBox, Stack } from '@/core/blocks';
import { Card, CardContent, HtmlMarkup, Typography } from '@/core/ui';
import { useTranslation } from 'react-i18next';

interface Section4Props {
  competition: {
    statistic_text: string;
  };
  extraInfo: {
    statistics: {
      games: {
        Matches: number;
        Victories: number;
        Draws: number;
        Defeats: number;
        GoalsScored: number;
        GoalsConceded: number;
      };
      goal_scorers: [
        {
          name: string;
          goals: number;
          player_image: string;
        },
      ];
    };
  };
}

export const Section4: React.FC<Section4Props> = ({ competition, extraInfo }) => {
  const statisticText = competition?.statistic_text || 'No statistic available';
  const games = extraInfo?.statistics?.games;
  const goalScorers = extraInfo?.statistics?.goal_scorers || [];

  const { t } = useTranslation("translation");

  return (
    <Card className="rounded-[40px] shadow-board w-full">
      <CardContent className="p-0">
        <div className="grid grid-cols-3">
          <div className="col-span-2 flex flex-col space-y-16 py-24 px-28 justify-between">
            <div className="flex flex-col space-y-6">
              <Typography className="uppercase text-[34px] leading-[34px] font-semibold tracking-[1.7px]">
                {t('shirt.statistics')}
              </Typography>
              <HtmlMarkup className="text-[26px] block max-h-44 overflow-y-auto no-scrollbar leading-[29.9px] tracking-[1.3px]">
                {statisticText}
              </HtmlMarkup>
            </div>
            <div className="flex space-x-32">
              <div className="flex flex-col space-y-10">
                <Typography className="uppercase text-[34px] leading-[32.3px] font-semibold tracking-[1.7px]">
                  {t('shirt.games')}
                </Typography>
                <div className="flex flex-row space-x-12">
                  <OliveBox text={t('shirt.games')} value={games?.Matches || 0} />
                  <div className="flex flex-col justify-between items-start">
                    <RowItem name={t('shirt.wins')} value={games?.Victories || 0} />
                    <RowItem name={t('shirt.draws')} value={games?.Draws || 0} />
                    <RowItem name={t('shirt.losses')} value={games?.Defeats || 0} />
                  </div>
                </div>
              </div>
              <div className="flex items-end">
                <Stack upperClass="w-[5px] h-[137px]" lowerClass="w-[5px] h-[73px]" />
              </div>
              {games && <GoalData stats={games} />}
            </div>
          </div>
          {goalScorers.length > 0 ? (
            <div className="col-span-1 flex flex-col space-y-10">
              <Card className="rounded-[40px] shadow-board p-24">
                <CardContent className="p-0 flex flex-col justify-center space-y-8 items-center">
                  <Typography className="text-[34px] text-center leading-[32.3px] font-semibold tracking-[1.7px] uppercase">
                  {t('shirt.best_scorer')}
                  </Typography>
                  <div className="flex flex-col space-y-5">
                    {goalScorers.map((scorer) => (
                      <div key={scorer?.name || 'unknown'} className="flex items-center space-x-4">
                        <img
                          src={scorer?.player_image || '/shirts/player-icon.png'}
                          alt={scorer?.name || 'Unknown'}
                          className="w-[62.83px] h-[62.83px]"
                        />
                        <Typography className="text-[24px] text-center leading-[24px] font-semibold tracking-[1.8px] uppercase">
                          {scorer?.name || 'Unknown'}
                        </Typography>
                      </div>
                    ))}
                  </div>
                  <div className="bg-olive rounded-[25px] w-[186px] h-[262px] flex flex-col items-center">
                    <div className="mt-3">
                      <Typography className="text-chalk text-[125px] font-bold">
                        {goalScorers.reduce((total, scorer) => total + (scorer?.goals || 0), 0)}
                      </Typography>
                    </div>
                    <Typography className="text-[26px] text-chalk tracking-[1.82px] leading-[26px] text-center uppercase font-semibold">
                    {t('shirt.scored_goals')}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ) : (
            <div className="col-span-1 flex items-center justify-center">
              <Typography className="text-[34px] text-gray-500">{t('shirt.no_top_scorer')}</Typography>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const RowItem = ({ value, name }: { value: number; name: string }) => {
  return (
    <div className='flex flex-row items-center justify-center space-x-5'>
      <div className='border border-content1 rounded-lg px-5 pt-1'>
        <Typography className='text-[36px] font-semibold'>{value}</Typography>
      </div>
      <Typography className='text-[26px] tracking-[1.82px] uppercase leading-[26px] font-semibold'>
        {name}
      </Typography>
    </div>
  );
};

const GoalItem = ({ title, matches, goals }: { title: string; matches: number; goals: number 
}) => {

  const { t } = useTranslation("translation");

  return (
    <div className='flex flex-row space-x-6'>
      <div className='bg-olive w-[90px] h-[90px] flex justify-center items-center rounded-2xl'>
        <Typography className='text-[56px] text-chalk font-bold'>{goals}</Typography>
      </div>
      <div className='flex flex-col justify-center'>
        <Typography className='break-keep leading-[26px] text-[26px] tracking-[1.82px] uppercase font-semibold'>
          {title}
        </Typography>
        <Typography className='text-lg leading-[18px] uppercase tracking-[1.26px]'>
          {t('shirt.average')} {(goals / matches).toFixed(2)} {t('shirt.per_game')}
        </Typography>
      </div>
    </div>
  );
};

interface GoalDataProps {
  stats: {
    Matches: number;
    GoalsScored: number;
    GoalsConceded: number;
  };
}

export const GoalData: React.FC<GoalDataProps> = ({ stats }) => {
  const { t } = useTranslation('translation');
  return (
    <div className='flex flex-col space-y-10'>
      <Typography className='text-3xl tracking-[1.5px] font-semibold uppercase'>{t('shirt.goals')}</Typography>
      <div className='flex flex-col space-y-[30px]'>
        <GoalItem title={t('shirt.scored_goals')} goals={stats.GoalsScored} matches={stats.Matches} />
        <GoalItem title={t('shirt.conceded_goals')} goals={stats.GoalsConceded} matches={stats.Matches} />
      </div>
    </div>
  );
};
