import { useState, useEffect } from 'react';
import { API } from '@/store/api';

const SCORE_INCREMENT = 3;

export const useQuizPlay = (playerName: string, difficulty: string) => {
  const [quizData, setQuizData] = useState<QuizData | null>(null);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStatus, setQuestionStatus] = useState<
    Array<'unanswered' | 'correct' | 'incorrect'>
  >([]);
  const [score, setScore] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);
  const [startQuiz, { isLoading, error }] = API.quizApi.useStartQuizMutation();

  const [playerId, setPlayerId] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const initializeQuiz = async (): Promise<void> => {
    try {
      const result = await startQuiz({ name: playerName, difficulty }).unwrap();
      const { timer, player, questions }: { timer: { value: number }, player: { id: number, name: string }, questions: Record<string, IQuizQuestion[]> } = result.data;
      const { id: playerId } = player;

      const levels = Object.keys(questions)
        .filter((key) => key.startsWith('level_'))
        .map((key, index) => ({
          level: index + 1,
          questions: questions[key].map((q: IQuizQuestion) => ({
            ...q,
            image: {
              path: q.image?.path || '',
              mime_type: q.image?.mime_type || '',
              caption: q.image?.caption || '',
            },
          })),
        }));

      setQuizData({
        playerName: player.name,
        levels,
        timer: timer.value,
      });

      setPlayerId(playerId);
      setQuestionStatus(Array(levels[0].questions.length).fill('unanswered'));
    } catch (err) {
      console.error('Error starting quiz:', err);
    }
  };

  useEffect(() => {
    initializeQuiz();
  }, [startQuiz, playerName, difficulty]);

  useEffect(() => {
    if (quizCompleted) return

    const timerId = setTimeout(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000)

    return () => clearTimeout(timerId);
  }, [elapsedTime]);

  const getNextNonEmptyLevelIndex = (currentIndex: number) => {
    return quizData?.levels.findIndex((level, index) => index > currentIndex && level.questions.length > 0) || -1;
  };

  const isLastQuestionAndLevel = () => {
    const currentLevel = quizData?.levels[currentLevelIndex];
    const isLastQuestion = currentLevel ? currentQuestionIndex === currentLevel.questions.length - 1 : false;
    const isLastLevel = getNextNonEmptyLevelIndex(currentLevelIndex) === -1;
    return isLastQuestion && isLastLevel;
  };

  const handleAnswerClick = (selectedAnswer: string) => {
    if (!quizData || isAnswering) return;

    setIsAnswering(true);

    const currentLevel = quizData.levels[currentLevelIndex];
    const currentQuestion = currentLevel.questions[currentQuestionIndex];
    const isCorrect = selectedAnswer === currentQuestion.correct_answer;

    setQuestionStatus((prevStatus) =>
      prevStatus.map((status, idx) => (idx === currentQuestionIndex ? (isCorrect ? 'correct' : 'incorrect') : status))
    );

    if (isCorrect) {
      setScore((prevScore) => prevScore + SCORE_INCREMENT);
      setCorrectAnswers((prevCorrect) => prevCorrect + 1);
    }

    setTimeout(() => {
      if (isLastQuestionAndLevel()) {
        setQuizCompleted(true);
      } else if (currentQuestionIndex === currentLevel.questions.length - 1) {
        const nextLevelIndex = getNextNonEmptyLevelIndex(currentLevelIndex);
        if (nextLevelIndex !== -1) {
          setCurrentLevelIndex(nextLevelIndex);
          setCurrentQuestionIndex(0);
          setQuestionStatus(Array(quizData.levels[nextLevelIndex].questions.length).fill('unanswered'));
        }
      } else {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
      setIsAnswering(false);
    }, 2000);
  };

  return {
    quizData,
    questionStatus,
    currentLevelIndex,
    currentQuestionIndex,
    handleAnswerClick,
    isLoading,
    error,
    quizCompleted,
    score,
    correctAnswers,
    playerId,
    elapsedTime,
  };
};
