import { HtmlMarkup, Card, CardContent, Typography } from '@/core/ui';
import { useEffect, useRef, useState } from 'react';

interface ICompetition {
  logo: IImage;
  poster: IImage;
  home_shirt: IImage;
  away_shirt: IImage;
  goalkeeper_shirt: IImage;
  shirts_description: string;
  squad_description: string;
  squad_image: IImage;
}

interface IImage {
  path: string;
  caption?: string;
}

export const Section1 = ({ competition }: { competition: ICompetition }) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [showGradient, setShowGradient] = useState(true);
  const [gradientPosition, setGradientPosition] = useState(0);
  let scrollTimeout: NodeJS.Timeout;

  const shirts = [
    { label: 'Camisola Principal', shirt: competition.home_shirt },
    { label: 'Camisola Alternativa', shirt: competition.away_shirt },
    { label: 'Camisola Guarda-Redes', shirt: competition.goalkeeper_shirt },
  ];

  const [currentFrontImage, setCurrentFrontImage] = useState(
    competition?.logo?.path || competition?.poster?.path || null,
  );
  const [currentBackImage, setCurrentBackImage] = useState<string | null>(null);
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    const images = [competition?.logo?.path, competition?.poster?.path].filter(Boolean);
    let index = 0;

    if (images.length > 1) {
      const interval = setInterval(() => {
        setFlipped((prev) => !prev);
        setTimeout(() => {
          index = (index + 1) % images.length;
          if (flipped) {
            setCurrentFrontImage(images[index]);
          } else {
            setCurrentBackImage(images[index]);
          }
        }, 500);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [competition]);

  if (!currentFrontImage) return null;

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableRef.current;
      setShowGradient(false);
      setGradientPosition(scrollTop);

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setShowGradient(scrollTop + clientHeight < scrollHeight);
      }, 150);
    }
  };

  return (
    <div className='grid grid-cols-12 gap-x-10 max-h-[783px]'>
      <Card className='rounded-[40px] shadow-board col-span-4'>
        <CardContent className='flex h-full items-center justify-center'>
          <div
            style={{
              perspective: '1000px',
            }}
            className='relative w-[366px] h-[400px] p-0'
          >
            <div
              style={{
                transformStyle: 'preserve-3d',
                transition: 'transform 0.5s',
                transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
              }}
              className='relative w-full h-full'
            >
              <div
                style={{
                  backfaceVisibility: 'hidden',
                }}
                className='absolute w-full h-full rounded-[40px]'
              >
                <img
                  src={currentFrontImage || ''}
                  className='absolute w-full h-full rounded-[40px] object-cover'
                  alt='Front'
                />
              </div>
              <div
                style={{
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(180deg)',
                }}
                className='absolute w-full h-full rounded-[40px]'
              >
                <img
                  src={currentBackImage || ''}
                  className='absolute w-full h-full rounded-[40px] object-cover'
                  alt='Back'
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className='rounded-[40px] shadow-board col-span-8 pt-28 pb-16 px-16 max-h-[776px] relative'>
        <CardContent className='flex flex-col items-center justify-center space-y-12'>
          <div className='flex flex-row space-x-8 justify-center items-center'>
            {shirts.map(({ label, shirt }, index) =>
              shirt.path ? (
                <div key={index} className='flex flex-col space-y-12 items-center h-full w-full'>
                  <Typography className='uppercase text-[34px] font-medium leading-[34px] tracking-[1.7px] max-w-48 text-center h-full'>
                    {label}
                  </Typography>
                  <img src={shirt.path} className='h-[264px] bg-contain' alt={shirt.caption || label} />
                </div>
              ) : null,
            )}
          </div>
          <div className='overflow-y-scroll max-h-[200px] relative pr-6 scrollbar scrollbar-w-2 scrollbar-thumb-[#C61D23]  scrollbar-thumb-rounded scrollbar-track-rounded' ref={scrollableRef} onScroll={handleScroll}>
          <HtmlMarkup className='text-[26px] text-justify leading-[29.9px] tracking-[1.3px] block max-w-[900px] max-h-full h-full'>
            {competition.shirts_description}
          </HtmlMarkup>
          {showGradient && (
            <div
              className='absolute left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none'
              style={{ bottom: -gradientPosition }}
            />
          )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Section1;
