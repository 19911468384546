import { Icon } from '@/core/icon';
import { cx } from '@/lib';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <main
      className={cx(
        'bg-contain bg-top bg-no-repeat bg-apple bg-phases',
        'flex flex-col items-center justify-start',
        'h-screen w-full py-36 xl:py-56',
      )}
    >
      <Icon name='OBJECT_LOGO_TEXT' className='min-w-60 min-h-60 xl:w-[366px] xl:h-[400px] xl:min-w-[366px] xl:min-h-[400px]' />
      <Outlet />
    </main>
  );
};
