import { Icon } from "@/core/icon";
import { Button, Typography } from "@/core/ui";

interface ButtonConfig {
  label: string;
  onClick: () => void;
}

interface PopupProps {
  headerText: string;
  bodyContent: string | JSX.Element;
  buttons: ButtonConfig[];
  showCloseButton?: boolean;
  onClose?: () => void;
}

export const Popup = ({
  headerText,
  bodyContent,
  buttons,
  showCloseButton = false,
  onClose,
}: PopupProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-xl z-20">
      <div className="bg-[#E7F2F1] flex flex-col justify-evenly rounded-[20px] p-8 h-[1149px] w-[1776px] relative shadow-lg mt-[1000px]">
        {showCloseButton && (
          <Button
            onClick={onClose}
            className="bg-transparent absolute top-16 right-4 flex items-center justify-center hover:bg-transparent shadow-none"
          >
              <Icon name="X_ICON" className="w-[88px] h-[88px]" />
          </Button>
        )}

        <div className="flex flex-col text-center space-y-12">
        <Typography className="uppercase text-center text-[70px] font-bold">
          {headerText}
        </Typography>
          {typeof bodyContent === "string" ? (
            <Typography className="text-[55px]">{bodyContent}</Typography>
          ) : (
            bodyContent
          )}
        </div>

        <div className="flex flex-col justify-center items-center space-y-12">
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={button.onClick}
              className={`px-6 py-3 rounded-md font-semibold w-[1478px] h-[124px] border border-black bg-white
                transition duration-200 ease-in-out
                hover:bg-black
                hover:text-white
                focus:bg-black
                active:bg-black`}
            >
              <Typography className="text-[55px] focus:text-white hover:text-white active:text-white">
                {button.label}
              </Typography>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
