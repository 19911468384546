import { Section1, Section2, Section3, Section4, Section5 } from '@/components/shirt-detail';
import { Matches } from '@/components/shirt-detail/matches';
import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Typography } from '@/core/ui';
import { LanguageSwitcher } from '@/layout/lang-switcher';
import { cx } from '@/lib/utils';
import { API } from '@/store/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export const Shirts = () => {
  const [toggle, setToggle] = useState(true);
  const { id } = useParams();
  const { i18n } = useTranslation('translation');

  const { t } = useTranslation("translation");

  const { data, isLoading } = API.shirtApi.useGetShirtDetailsQuery({
    shirtId: id,
    locale: i18n.language,
  });

  if (isLoading) {
    return (
      <main className='flex flex-col justify-center items-center h-screen w-full'>
        <Typography className='text-xl'>Loading...</Typography>
      </main>
    );
  }

  if (!data) {
    return (
      <main className='flex flex-col justify-center items-center h-screen w-full'>
        <Typography className='text-xl'>No data available</Typography>
      </main>
    );
  }

  const { competition, extra_info } = data;

  return (
    <main
      className={cx(
        'bg-contain bg-top bg-no-repeat bg-apple bg-phases',
        'flex flex-col justify-between items-center',
        'h-screen w-full items-center pb-40 pt-56 px-[12rem]',
      )}
    >
      <Icon name='OBJECT_LOGO_TEXT' className='w-[366px] h-[400px] flex-shrink-0 object-contain' />
      <div className='flex flex-col space-y-10 h-full mt-28 w-full'>
        <Section1 competition={competition} />
        {toggle ? (
          <>
            {competition.has_external_matches ? <Section2 extraInfo={extra_info} /> : <Matches data={competition} /> }
            <Section3 competition={competition} />
          </>
        ) : (
          <>
            <Section4 competition={competition} extraInfo={competition.has_external_statistics ? extra_info : competition} />
            <Section5 competition={competition} />
          </>
        )}
      </div>

      <div className='w-full'>
        <div className='flex flex-row justify-between items-center'>
          <Link to={ROUTES.HOME}>
            <Button className='bg-danger rounded-full px-12 py-14 items-center justify-center'>
              <Icon name='CHEVRON_LEFT' className='w-9 h-9 mr-5' />
              <Typography className='font-semibold uppercase text-[33px] leading-[31px] text-center text-chalk'>
                {t('home.buttons.back')}
              </Typography>
            </Button>
          </Link>
          <Button
            className='bg-danger rounded-full px-16 py-14 w-[420px] items-center justify-center'
            onClick={() => setToggle(!toggle)}
          >
            <Typography className='font-semibold uppercase text-[33px] leading-[31px] text-center text-chalk'>
              {toggle ? t('shirt.statistics_squad') : t('shirt.results_gallery')}
            </Typography>
          </Button>
          <LanguageSwitcher />
        </div>
      </div>
    </main>
  );
};

export default Shirts;
