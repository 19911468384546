import { APP_TYPE, envVars } from '@/config';
import { useAppDispatch, useAppSelector } from '@/store';
import { changeIdle } from '@/store/actions';
import { useGetConfigQuery, useGetScreenSaverQuery } from '@/store/global/api';
import { useIdleTimer } from 'react-idle-timer/legacy';

export const useIdle = () => {
  const { isLoading } = useGetConfigQuery({ app: APP_TYPE.SHIRT });
  const { data } = useGetScreenSaverQuery({ app: APP_TYPE.SHIRT });
  const dispatch = useAppDispatch();
  const isIdle = useAppSelector((s) => s.global.isIdle);
  const globalLoading = useAppSelector((s) => s.global.globalLoading);

  const DEFAULT_IDLE_TIMEOUT = 180;

  const onIdle = () => {
    dispatch(changeIdle(true));
  };

  const { reset } = useIdleTimer({
    timeout: (data?.settings?.time_until_screensaver ?? DEFAULT_IDLE_TIMEOUT) * 1000,
    throttle: 500,
    disabled: envVars.underMaintenance || globalLoading || isLoading,
    // promptBeforeIdle: 1000 * 30,
    // onAction,
    // onActive,
    // onPrompt,
    onIdle,
  });

  const onReset = () => {
    dispatch(changeIdle(false));
    reset();
  };

  return {
    onReset,
    globalLoading,
    isIdle,
  };
};
