import { PageHeader } from "@/components/PageHeader";
import { Button, Typography } from "@/core/ui";
import { type Key, useState } from "react";
import { Popup } from "@/components/Popup";
import VirtualKeyboard from "@/components/VirtualKeyboard";
import { ROUTES } from "@/config";
import { Link, useNavigate } from "react-router-dom";
import { API } from "@/store/api";
import { useTranslation } from "react-i18next";
import QuizLoading from "@/components/quiz/QuizLoading";
import React from "react";
import { LanguageSwitcher } from "@/layout/lang-switcher";
import { Icon } from "@/core/icon";

const chunkArray = (array: any[], chunkSize: number) => {
  return array.reduce((acc: any[], item: any, index: number) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(item);
    return acc;
  }, []);
};

const Quiz = () => {
  const { t } = useTranslation("translation");

  const [playerName, setPlayerName] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { isLoading, isFetching, error, data } =
    API.quizApi.useGetQuizStepsQuery({
      locale: i18n.language,
    });

  const quizSteps = data?.data?.steps || [];

  const chunkedQuizSteps = chunkArray(quizSteps, 2);

  const handleStartQuiz = () => setIsPopupVisible(true);
  const handleClosePopup = () => setIsPopupVisible(false);
  const isStartQuizDisabled = () => playerName.trim() === "";
  const handleDifficultyLevel = (difficulty: string) => {
    navigate(ROUTES.QUIZ_PLAY, {
      state: { playerName, difficulty },
    });
  };

  const QuizInstruction = ({ step, text }: { step: number; text: string }) => {
    const numberImage = `/numbers/${step}.png`;

    return (
    <div className="flex flex-row items-center justify-center space-y-4">
      <img src={numberImage} alt={`Step ${step}`} className="relative left-7"/>
      <Typography className="text-[40px] w-[464px] leading-[38px] text-neutral-800 z-10">
        {text}
      </Typography>
    </div>
    );
};

  const Divider = () => (
    <div className="flex justify-center w-full">
      <div className="h-[6px] w-[90%] flex">
        <div className="flex-1 bg-[#509B85]" />
        <div className="flex-[496px] bg-[#C61D23]" />
      </div>
    </div>
  );

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !quizSteps) {
    return <QuizLoading />;
  }

  return (
    <div className="pt-[184px] h-full flex items-center justify-between flex-col w-full ">
      <header className="w-full flex flex-col items-center space-y-12 px-[192px]">
        <PageHeader
          data={{
            title: t("quiz.title"),
            secondaryTitle: t("quiz.subtitle"),
          }}
        />
        <div className="bg-[#FCFBF9] rounded-[50px] px-[177px] py-[60px] h-[907px] w-full shadow-lg flex flex-col justify-between items-center">
          {chunkedQuizSteps.map((chunk, chunkIndex) => (
            <React.Fragment key={chunkIndex}>
              <div className="flex justify-center w-full h-full">
                <div className="grid grid-cols-2 w-full gap-16 text-[60px] leading-[70px]">
                  {chunk.map((stepData: { id: Key; value: string }, index: number) => (
                    <QuizInstruction
                      key={stepData.id}
                      step={chunkIndex * 2 + index + 1}
                      text={stepData.value}
                    />
                  ))}
                </div>
              </div>
              {chunkIndex < chunkedQuizSteps.length - 1 && (
                <Divider />
              )}
            </React.Fragment>
          ))}
        </div>
      </header>

      <div className="w-[1776px] flex flex-col items-center">
        <Typography className="uppercase text-[60px] tracking-wide mb-6">
          {t("quiz.player.name")}
        </Typography>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder={t("quiz.player.placeholder")}
          className="w-full h-[190px] text-center text-[60px] p-10 mb-[90px] border-2 rounded-[20px] border-[#231F20] focus:outline-none"
          style={{ fontFamily: "united-semi-sans, United Sans SmCd Md" }}
        />
        <VirtualKeyboard setPlayerName={setPlayerName} />
      </div>

      <div className="flex justify-between w-full px-48 mt-32 space-x-12">
        <Link to={ROUTES.HOME}>
          <Button className="w-fit h-[108px] bg-danger rounded-full px-[56px] py-[38px] flex items-center justify-center">
            <Icon name="CHEVRON_LEFT" className="w-9 h-9 mr-5" />
            <Typography className="text-[33px] text-white uppercase">
              {t("quiz.buttons.exit_quiz")}
            </Typography>
          </Button>
        </Link>
        <Button
          className="w-fit h-[108px] bg-danger rounded-full px-[56px] py-[38px] flex items-center justify-center disabled:opacity-50"
          onClick={handleStartQuiz}
          disabled={isStartQuizDisabled()}
        >
          <Typography className="text-[33px] text-white uppercase">
            {t("quiz.buttons.new_quiz")}
          </Typography>
          <Icon name="CHEVRON_RIGHT" className="w-9 h-9 ml-5" />
        </Button>
        <LanguageSwitcher />
      </div>

      {isPopupVisible && (
        <Popup
          headerText={`${t(
            "quiz.popup.greeting"
          )}, ${playerName.toUpperCase()}!`}
          bodyContent={t("quiz.popup.difficulty_prompt")}
          buttons={[
            {
              label: t("quiz.popup.easy"),
              onClick: () => handleDifficultyLevel("EASY"),
            },
            {
              label: t("quiz.popup.medium"),
              onClick: () => handleDifficultyLevel("MEDIUM"),
            },
            {
              label: t("quiz.popup.hard"),
              onClick: () => handleDifficultyLevel("HARD"),
            },
          ]}
          showCloseButton
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Quiz;
