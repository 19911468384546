export const PAUSE = ({ color, ...props }: React.ComponentPropsWithRef<'svg'>) => (
  <svg width='95' height='95' viewBox='0 0 95 95' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_b_2339_18829)'>
      <rect
        x='0.978192'
        y='1.05949'
        width='92.432'
        height='92.432'
        rx='46.216'
        stroke={color ?? 'rgb(var(--color-chalk))'}
        strokeWidth='1.96664'
      />
      <rect x='38.2039' y='33.79' width='4.49517' height='26.971' fill={color ?? 'rgb(var(--color-chalk))'} />
      <rect x='51.6895' y='33.79' width='4.49517' height='26.971' fill={color ?? 'rgb(var(--color-chalk))'} />
    </g>
    <defs>
      <filter
        id='filter0_b_2339_18829'
        x='-4.05648'
        y='-3.97518'
        width='102.501'
        height='102.501'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.02568' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2339_18829' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_2339_18829' result='shape' />
      </filter>
    </defs>
  </svg>
);
