import ModelViewer from '@/components/ModelViewer';
import { PageHeader } from '@/components/PageHeader';
import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Card, CardContent, Typography } from '@/core/ui';
import { LanguageSwitcher } from '@/layout/lang-switcher';
import { API } from '@/store/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { cx } from '@/lib';

import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom';

import type { ICompetition, IModality } from '@/types/models/modality';

const Home = () => {
  const { data: modalities, refetch: refetchModalities } = API.modalityApi.useGetAllModalitiesQuery();
  const { i18n, t } = useTranslation('translation');
  const navigate = useNavigate();

  const [competitions, setCompetitions] = useState<ICompetition[]>([]);
  const [selectedCompetition, setSelectedCompetition] = useState<ICompetition>();
  const [modalityId, setModalityId] = useState<number>(1);
  const [gender, setGender] = useState<string>('male');

  const [page, setPage] = useState(1);

  const [rotation, setRotation] = useState(0);

  const handleChevronLeft = () => {
    setRotation((prev) => prev - Math.PI / 6);
  };

  const handleChevronRight = () => {
    setRotation((prev) => prev + Math.PI / 6);
  };

  const handleIconClick = () => {
    if (selectedCompetition) {
      navigate(`/shirt/${selectedCompetition.id}`);
    }
  };

  const { data, isFetching } = API.modalityApi.useGetCompetitionsQuery({
    modalityId: modalityId,
    gender: gender,
    page,
    locale: i18n.language,
  });

  useEffect(() => {
    setCompetitions([]);

    return () => {
      setCompetitions([]);
    };
  }, []);

  useEffect(() => {
    setPage(1);
    setCompetitions([]);
  }, [modalityId, gender]);

  useEffect(() => {
    if (data) {
      setCompetitions((prev) => {
        const newCompetitions = data.competitions.filter((comp) => !prev.some((p) => p.id === comp.id));
        return [...prev, ...newCompetitions];
      });
    }
  }, [data]);

  useEffect(() => {
    if (competitions && competitions.length > 0 && !selectedCompetition) {
      setSelectedCompetition(competitions[0]);
    }
  }, [competitions, selectedCompetition]);

  useEffect(() => {
    refetchModalities();
  }, [i18n.language]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;

    const scrollLeft = element.scrollLeft;
    const scrollWidth = element.scrollWidth;
    const clientWidth = element.clientWidth;

    const scrollThresholdReached = Math.ceil(scrollLeft + clientWidth) >= scrollWidth - 20;
    const isLastPage = data && page >= data.pagination.last_page;

    if (scrollThresholdReached && !isLastPage && !isFetching) {
      console.log('Setting new page:', page + 1);
      setPage((prev) => prev + 1);
    }
  };

  const selectedModalityIndex =
    modalities?.findIndex((modality: IModality) => modality.id === modalityId) ?? 0;
  let dynamicMarginLeft = 0;
  if (selectedModalityIndex === 1) {
    dynamicMarginLeft = 300;
  } else if (selectedModalityIndex === 2) {
    dynamicMarginLeft = 700;
  }

  return (
    <div className='mt-16 lg:mt-[88px] h-full flex items-center justify-between flex-col w-full'>
      <PageHeader
        data={{
          title: data?.app_title ?? '',
          secondaryTitle: selectedCompetition ? selectedCompetition.name : t('home.subtitle'),
        }}
      />
      <div className='h-full w-full my-auto relative'>
        <div className='absolute z-10 top-[58%] rounded-full bg-olive/60 left-[28%] backdrop-blur-lg'>
          <Icon name='PLUS' className='h-auto w-44' onClick={handleIconClick} />
        </div>
        {selectedCompetition?.shirt_3d_model?.path ? (
          <ModelViewer path={selectedCompetition.shirt_3d_model.path} rotation={rotation} />
        ) : null}
      </div>
      <div className='flex flex-col w-full px-44 pb-28'>
        <div className='flex justify-center'>
          <span />
          <div className='flex flex-row space-x-10 items-center'>
            <Icon
              name='CHEVRON_LEFT'
              className='w-14 h-14'
              color='rgb(var(--color-content1))'
              onClick={handleChevronLeft}
            />
            <Icon name='THREE_D' />
            <Icon
              name='CHEVRON_RIGHT'
              className='w-14 h-14'
              color='rgb(var(--color-content1))'
              onClick={handleChevronRight}
            />
          </div>
        </div>
        <div className='flex relative justify-end z-20'>
          <span />
          <Link
            to={ROUTES.QUIZ}
            className='bg-danger cursor-pointer rounded-full px-16 py-14 flex items-center justify-center'
          >
            <Typography className='uppercase font-semibold text-[33px] leading-[31px] text-center text-chalk'>
              {t('home.buttons.quiz_challenge')}
            </Typography>
          </Link>
        </div>
      </div>
      <div className='flex flex-col w-full'>
        {competitions && (
          <ShirtCarousel
            competitions={competitions}
            selectedCompetition={selectedCompetition ?? competitions[0]}
            onCompetitionClick={setSelectedCompetition}
            handleScroll={handleScroll}
            page={page}
            isFetching={isFetching}
          />
        )}
      </div>
      <div className='flex justify-between items-center w-full mt-20 px-32'>
        <div className='flex flex-col justify-center space-y-10'>
          <div className='flex flex-row space-x-10'>
            {modalities?.map((modality: IModality) => (
              <Button
                key={modality.id}
                className={cx(
                  'border-2 rounded-full px-16 py-14 items-center justify-center bg-chalk focus:bg-danger active:bg-danger ',
                  modalityId === modality.id ? 'bg-danger' : 'border-neutral-300',
                )}
                onClick={() => setModalityId(modality.id)}
              >
                <Typography
                  className={cx(
                    'uppercase font-semibold text-[33px] leading-[31px] text-center',
                    modalityId === modality.id ? 'text-chalk' : 'text-neutral-300',
                  )}
                >
                  {modality.name}
                </Typography>
              </Button>
            ))}
          </div>
          <div className='flex flex-row space-x-10' style={{ marginLeft: `${dynamicMarginLeft}px` }}>
            {modalities?.[0]?.available_genders?.map((g: string) => (
              <Button
                key={g}
                className={cx(
                  'border-2 bg-chalk rounded-full px-8 py-10 items-center justify-center focus:bg-chalk active:bg-chalk',
                  gender === g ? 'border-red-600' : 'border-neutral-300',
                )}
                onClick={() => setGender(g)}
              >
                <Typography
                  className={cx(
                    'uppercase text-red-600 font-semibold text-[33px] leading-[31px] text-center',
                    gender === g ? 'text-red-600' : 'text-neutral-300',
                  )}
                >
                  {g.charAt(0).toUpperCase()}
                </Typography>
              </Button>
            ))}
          </div>
        </div>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Home;

const ShirtCarousel = ({
  competitions = [],
  onCompetitionClick,
  selectedCompetition,
  handleScroll,
  page,
  isFetching,
}: {
  competitions: ICompetition[];
  onCompetitionClick: (competition: ICompetition) => void;
  selectedCompetition: ICompetition;
  handleScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  page: number;
  isFetching: boolean;
}) => {
  return (
    <div
      className='pl-32 flex flex-row space-x-12 pr-32 overflow-x-scroll overflow-y-scroll scrollbar-none'
      onScroll={handleScroll}
    >
      {isFetching && competitions.length === 0
        ? Array.from({ length: 5 }).map((_, idx) => (
            <motion.div
              key={`placeholder-${idx}`}
              className='rounded-[26px] w-[383px] h-[383px] flex flex-col justify-center bg-neutral-200 animate-pulse no-scrollbar'
              initial={{ y: 300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.1 * idx,
                duration: 0.5,
              }}
            >
              <div className='flex flex-col items-center justify-center space-y-5 h-full'>
                <div className='h-[200px] w-[150px] bg-neutral-300 rounded' />
                <div className='w-[313px] h-8 bg-neutral-300 rounded' />
              </div>
            </motion.div>
          ))
        : competitions.map((competition, idx) => (
            <motion.div
              key={competition.id}
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: page > 1 ? 0.1 * idx : 0.5 * idx,
                duration: 0.5,
              }}
              onClick={() => onCompetitionClick(competition)}
            >
              <Card
                className={cx(
                  'rounded-[26px] w-[383px] h-[383px] flex flex-col justify-center',
                  competition.id === selectedCompetition?.id ? 'border-4 border-[#509B85]' : '',
                )}
              >
                <CardContent className='flex flex-col items-center justify-between h-full my-8'>
                  <img
                    src={competition.home_shirt?.path || '/shirts/portugal-shirt.png'}
                    alt={competition.home_shirt?.caption}
                    className='h-[200px] w-auto object-fit'
                  />
                  <Typography className='uppercase text-[37px] leading-[35px] tracking-[5%] font-semibold text-center w-[313px]'>
                    {competition.name}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          ))}
    </div>
  );
};
