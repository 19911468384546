import { Canvas } from '@react-three/fiber';
import {
  OrbitControls,
  useGLTF,
  Html,
  OrthographicCamera,
  AccumulativeShadows,
  Environment,
} from '@react-three/drei';
import { Suspense, useRef, useEffect } from 'react';
import { type Object3D, Box3, Vector3 } from 'three';

interface ModelProps {
  path: string;
  rotation: number;
}

const Model: React.FC<ModelProps> = ({ path, rotation }) => {
  const { scene } = useGLTF(path);
  const modelRef = useRef<Object3D>(null);

  useEffect(() => {
    if (modelRef.current) {
      const box = new Box3().setFromObject(modelRef.current);
      const size = box.getSize(new Vector3());
      const center = box.getCenter(new Vector3());

      const maxAxis = Math.max(size.x, size.y, size.z);
      const scale = 5.8 / maxAxis;
      modelRef.current.scale.set(scale, scale, scale);

      modelRef.current.position.set(-center.x * scale, -center.y * scale, -center.z * scale);
    }
    return () => {
      useGLTF.clear(path);
    };
  }, [scene, path]);

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y = rotation;
    }
  }, [rotation]);

  return <primitive object={scene} ref={modelRef} />;
};

const Loader: React.FC = () => {
  return (
    <Html center>
      <div style={{ textAlign: 'center', color: 'black' }}>
        <div
          className='w-32 h-32 border-[10px] border-[#c9c9c9] border-t-[10px] border-t-[#C61D23] rounded-[50%] animate-spin'
        />
      </div>
    </Html>
  );
};

const ModelViewer: React.FC<ModelProps> = ({ path, rotation }) => {
  const encodedPath = encodeURI(path);

  return (
    <div style={{ width: '100%', height: '90%', maxHeight: '90vh', margin: '0 auto' }}>
      <Canvas shadows>
        <OrthographicCamera makeDefault zoom={200} position={[0, 0, 5]} castShadow={true} />
        <spotLight
          position={[0, 0, 10]}
          angle={0.3}
          penumbra={1}
          castShadow
          intensity={2}
          shadow-bias={-0.0001}
        />
        <ambientLight intensity={0.5} />
        <AccumulativeShadows
          position={[0, -1.16, 0]}
          frames={100}
          alphaTest={0.9}
          scale={10}
        />
        <Environment preset='studio' blur={1} />
        <Suspense fallback={<Loader />}>
          <Model path={encodedPath} rotation={rotation} />
        </Suspense>
        <OrbitControls
          autoRotate
          autoRotateSpeed={1.0}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          enablePan={false}
          enableZoom={false}
          minAzimuthAngle={Number.NEGATIVE_INFINITY}
          maxAzimuthAngle={Number.POSITIVE_INFINITY}
        />
      </Canvas>
    </div>
  );
};

export default ModelViewer;
