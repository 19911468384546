import { useState } from "react";
import { Button, Typography } from "@/core/ui";

interface VirtualKeyboardProps {
  setPlayerName: React.Dispatch<React.SetStateAction<string>>;
}

interface KeyboardButtonProps {
  keyValue: string;
  onClick: () => void;
  color?: string;
  colSpan?: number;
  flexGrow?: boolean;
}

const KeyboardButton = ({
  keyValue,
  onClick,
  color = 'bg-[#404A50]',
  colSpan,
  flexGrow = false,
}: KeyboardButtonProps) => (
  <Button
    className={`w-[123px] h-[148px] p-6 ${color} rounded-2xl ${
      colSpan ? `col-span-${colSpan}` : ""
    } ${flexGrow ? "flex-grow" : ""}`}
    onClick={onClick}
  >
    <Typography className="text-[60px] text-[#D9DBDC] font-roboto">{keyValue}</Typography>
  </Button>
);

const VirtualKeyboard = ({ setPlayerName }: VirtualKeyboardProps) => {
  const [isNumberKeyboard, setIsNumberKeyboard] = useState(false);

  const handleKeyClick = (key: string) => {
    setPlayerName((prevName: string) => prevName + key);
  };

  const handleBackspace = () => {
    setPlayerName((prevName: string) => prevName.slice(0, -1));
  };

  const toggleKeyboardMode = () => {
    setIsNumberKeyboard((prevMode) => !prevMode);
  };

  const letters = "QWERTYUIOPASDFGHJKLÇZXCVBNM".split("");
  const numberLetters = "ASDFGHJKLÇZXCVBNM".split("");
  const numbers = "1234567890".split("");

  return (
    <div className="w-[1400px] mt-8 grid gap-4">
      {/* Row 1 */}
      <div className="grid grid-cols-10 gap-4">
        {(isNumberKeyboard ? numbers : letters.slice(0, 10)).map((key) => (
          <KeyboardButton key={key} keyValue={key} onClick={() => handleKeyClick(key)} />
        ))}
      </div>

      {isNumberKeyboard && (
        <div className="grid grid-cols-10 gap-4">
          {numberLetters.map((key) => (
            <KeyboardButton key={key} keyValue={key} onClick={() => handleKeyClick(key)} />
          ))}
          <div className="col-span-2" />
          <KeyboardButton keyValue="⌫" color="bg-[#313C42]" onClick={handleBackspace} />
        </div>
      )}

      {/* Row 2 */}
      {!isNumberKeyboard && (
        <div className="grid grid-cols-10 gap-4">
          {letters.slice(10, 20).map((key) => (
            <KeyboardButton key={key} keyValue={key} onClick={() => handleKeyClick(key)} />
          ))}
        </div>
      )}

      {/* Row 3 */}
      {!isNumberKeyboard && (
        <div className="flex justify-between gap-4">
          {letters.slice(20).map((key) => (
            <KeyboardButton key={key} keyValue={key} onClick={() => handleKeyClick(key)} />
          ))}
          <KeyboardButton keyValue="⌫" color="bg-[#313C42]" onClick={handleBackspace} />
        </div>
      )}

      {/* Row 4: Special Keys */}
      <div className="flex gap-4">
        <KeyboardButton keyValue={isNumberKeyboard ? "ABC" : "123"} onClick={toggleKeyboardMode} />
        <KeyboardButton keyValue="," color="bg-[#313C42]" onClick={() => handleKeyClick(",")} />
        <KeyboardButton keyValue=" "
          onClick={() => handleKeyClick(" ")}
          flexGrow={true}
        />
        <KeyboardButton keyValue="." color="bg-[#313C42]" onClick={() => handleKeyClick(".")} />
        <KeyboardButton keyValue="↵" color="bg-[#509B85]"onClick={() => console.log("Submit")} />
      </div>
    </div>
  );
};

export default VirtualKeyboard;
