import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import { Button, Typography } from '@/core/ui';
import { LanguageSwitcher } from '@/layout/lang-switcher';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Leaderboard: React.FC = () => {
  const { t } = useTranslation('translation');

  const { state } = useLocation();
  const {
    score,
    correctAnswers,
    totalQuestions,
    playerName,
    finalLevel,
    top11,
    position,
    playerId,
    difficulty,
  } = state || {};

  if (!state) return <p>Invalid data</p>;

  const levelNumber = finalLevel?.replace('LEVEL_', '');

  return (
    <div className='mx-[192px] w-[1776px]'>
      <div className='flex flex-col bg-white rounded-[30px] p-12 h-[870px] w-full mx-auto text-center' style={{ boxShadow: '0px 0px 50px 0px #0000001A' }}>
        <div className='flex justify-around text-center mt-8'>
          <div className='flex flex-1 flex-col items-center'>
            <Typography className='text-[40px] uppercase text-gray-700'>
              {t('leaderboard.titles.level')}
            </Typography>
            <div className='bg-[#509B85] rounded-[20px] w-[210px] h-[210px] flex items-center justify-center mt-[42px]'>
              <Typography className='text-[150px] font-bold text-white'>{levelNumber}</Typography>
            </div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <Typography className='text-[40px] uppercase text-gray-700'>
              {t('leaderboard.titles.answers')}
            </Typography>
            <div className='bg-[#509B85] rounded-[20px] w-[420px] h-[210px] flex items-center justify-center mt-[42px]'>
              <Typography className='text-[150px] font-bold text-white'>
                {`${correctAnswers}/${totalQuestions}`}
              </Typography>
            </div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <Typography className='text-[40px] uppercase text-gray-700'>
              {t('leaderboard.titles.final_score')}
            </Typography>
            <div className='bg-[#509B85] rounded-[20px] w-[420px] h-[210px] flex items-center justify-center mt-[42px]'>
              <Typography className='text-[150px] font-bold text-white'>{score}</Typography>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center mt-[108px]'>
          <Typography className='text-[120px] font-bold uppercase'>
            {t('leaderboard.titles.congratulations')}
          </Typography>
          <Typography className='text-6xl mt-8 text-center text-gray-600 w-[1370px]'>
            {t('leaderboard.titles.placeholder_message')}
          </Typography>
        </div>
      </div>
      <div className='bg-white w-full h-[1748px] rounded-[30px] p-8 mx-auto mt-14' style={{ boxShadow: '0px 0px 50px 0px #0000001A' }}>
        <div className='flex justify-center items-center text-neutral-600 border-[3px] rounded-[20px] border-black h-[78px] w-fit mx-auto px-[35px] mt-20'>
          <Typography className='text-[40px] uppercase px-7 py-5'>
            {t('leaderboard.titles.difficulty')} <strong className='text-black'>{difficulty}</strong>
          </Typography>
        </div>
        <div className='overflow-auto h-[1415px] w-[1500px] mt-[65px] mx-auto'>
          <table className='w-full h-full text-left table-fixed border-separate border-spacing-y-6'>
            <thead>
              <tr>
                <th className='text-center w-[10%]'>
                  <Typography className='text-[40px] uppercase'>{t('leaderboard.table.class')}</Typography>
                </th>
                <th className='w-[40%]'>
                  <Typography className='text-[40px] uppercase'>{t('leaderboard.table.name')}</Typography>
                </th>
                <th className='text-center w-[10%]'>
                  <Typography className='text-[40px] uppercase'>{t('leaderboard.table.level')}</Typography>
                </th>
                <th className='text-center w-[20%]'>
                  <Typography className='text-[40px] uppercase'>{t('leaderboard.table.answers')}</Typography>
                </th>
                <th className='text-center w-[20%]'>
                  <Typography className='text-[40px] uppercase'>{t('leaderboard.table.score')}</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {position === null
              ? top11
                .slice(0, 10)
                .map(({ id, name, level, correct_answers, score }: IQuizTop11, idx: number) => {
                  const levelNumber = level.replace('LEVEL_', '');
                  return (
                    <tr key={id} className={`${id === playerId ? 'bg-neutral-300' : ''}`}>
                    <td className='text-center'>
                    <Typography className='text-[35px] font-semibold uppercase border-[4px] rounded-full border-black flex justify-center items-center w-[72px] h-[72px] mx-auto'>
                      {idx + 1}
                    </Typography>
                    </td>
                    <td>
                    <Typography className='text-[40px] uppercase'>{name}</Typography>
                    </td>
                    <td className='text-center rounded-none'>
                    <Typography className='text-[51px] bg-[#509B85] py-4 px-8 rounded-[10px] font-semibold text-white uppercase'>
                      {levelNumber}
                    </Typography>
                    </td>
                    <td className='text-center'>
                    <Typography className='text-[51px] bg-[#509B85] py-4 px-8 rounded-[10px] font-semibold text-white uppercase'>
                      {`${correct_answers}/${totalQuestions}`}
                    </Typography>
                    </td>
                    <td className='text-center'>
                    <Typography className='text-[51px] bg-[#509B85] py-4 px-8 rounded-[10px] font-semibold text-white uppercase'>{score}</Typography>
                    </td>
                  </tr>
                  );
                })
              : top11.map(({ id, name, level, correct_answers, score }: IQuizTop11, idx: number) => {
                const levelNumber = level.replace('LEVEL_', '');
                return (
                  <tr key={id} className={`${id === playerId ? 'bg-[#D2D1D0]' : ''}`}>
                  <td className='text-center'>
                    <Typography>{idx + 1}</Typography>
                  </td>
                  <td>
                    <Typography>{name}</Typography>
                  </td>
                  <td className='text-center'>
                    <Typography>{levelNumber}</Typography>
                  </td>
                  <td className='text-center'>
                    <Typography>{`${correct_answers}`}</Typography>
                  </td>
                  <td className='text-center'>
                    <Typography>{score}</Typography>
                  </td>
                  </tr>
                );
                })}

              {position !== null && (
              <>
                <tr>
                <td colSpan={5}>
                  <hr className='my-4 border-red-600' />
                </td>
                </tr>
                <tr className='bg-[#D2D1D0]'>
                <td className='text-center'>
                  <Typography>{position}</Typography>
                </td>
                <td >
                  <Typography>{playerName}</Typography>
                </td>
                <td className='text-center'>
                  <Typography>{levelNumber}</Typography>
                </td>
                <td className='text-center'>
                  <Typography>{`${correctAnswers}`}</Typography>
                </td>
                <td className='text-center'>
                  <Typography>{score}</Typography>
                </td>
                </tr>
              </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex flex-row justify-between items-center mt-[116px]'>
          <Link to={ROUTES.HOME}>
            <Button className='bg-danger rounded-full px-12 py-14 items-center justify-center'>
              <Icon name='CHEVRON_LEFT' className='w-9 h-9 mr-5' />
              <Typography className='font-semibold uppercase text-[33px] leading-[31px] text-center text-chalk'>
              {t('leaderboard.buttons.exit_quiz')}
              </Typography>
            </Button>
          </Link>
          <LanguageSwitcher />
        </div>
    </div>
  );
};

export default Leaderboard;
