import { Typography } from "@/core/ui";

interface PageHeaderProps {
  data: {
    title: string;
    secondaryTitle?: string;
    tertiaryTitle?: string;
  };
}

export const PageHeader = ({ data }: PageHeaderProps) => {
  const { title, secondaryTitle } = data;

  return (
    <div className="flex flex-col space-y-16">
      <Typography className="uppercase text-8xl lg:text-[120px] lg:max-w-[90rem] lg:leading-[114px] font-bold text-center lg:tracking-[8.4px]">
        {title}
      </Typography>

      {secondaryTitle && (
        <Typography className="uppercase text-4xl lg:text-[65px] lg:leading-[65px] text-center lg:tracking-[15%]">
          {secondaryTitle}
        </Typography>
      )}
    </div>
  );
};
